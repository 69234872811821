// eslint-disable-next-line
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import BaseVue from "@/plugin/BaseVue";
import * as model from "./Model";
import axios from "axios";
import TextareaAutoResize from "@/components/TextareaAutoResize/TextareaAutoResize.vue";
import InputPrice from "@/components/InputPrice/InputPrice.vue";

@Component({
  components: {
    TextareaAutoResize,
    InputPrice,
  },
})
export default class extends BaseVue {
  public model: model.Model = new model.Model();
  public created() {
    const routeInstance = this.$route;
    this.setMeta(routeInstance);
  }
  public async mounted() {
    await this.authCheck();
    this.model.pageLoading = true;
    //
    this.model.pageLoading = false;
  }
  public validationCheck(target = "", inputing = false, auto = false): boolean {
    if (!auto && !inputing) {
      this.model.saveButtonCompleted = false;
    }
    let result = true;
    let input: any;
    let errors: any;
    if (target == "all" || target == "name") {
      input = this.model.name;
      errors = new Array<model.Error>();
      //
      if (!inputing) {
        input.value = input.value.trim();
        input.value = this.hankaku2Zenkaku(input.value);
      }
      if (input.value.length === 0) {
        errors.push(new model.Error("必須項目です"));
      } else if (input.value.length < input.minLength) {
        errors.push(
          new model.Error(`${input.minLength}文字以上で入力してください`)
        );
      }
      if (input.value.length > input.maxLength) {
        errors.push(
          new model.Error(`${input.maxLength}文字以下で入力してください`)
        );
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError) {
        result = false;
      }
      //
    }
    if (target == "all" || target == "explanation") {
      input = this.model.explanation;
      errors = new Array<model.Error>();
      //
      if (input.value.length > input.maxLength) {
        errors.push(
          new model.Error(`${input.maxLength}文字以下で入力してください`)
        );
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError) {
        result = false;
      }
      //
    }
    if (target == "all" || target == "price") {
      input = this.model.price;
      errors = new Array<model.Error>();
      //
      if (!inputing) {
        input.value = this.hankaku2Zenkaku(input.value);
        input.value = input.value.replace(/[^0-9]/g, "");
      }
      if (input.value.length === 0) {
        errors.push(new model.Error("月額を設定してください"));
      } else if (input.value < 100) {
        errors.push(new model.Error(`月額は\\100以上で設定してください`));
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError) {
        result = false;
      }
      //
    }
    return result;
  }
  public async save() {
    this.model.pageLoading = true;
    this.model.saveButtonActive = false;
    this.model.saveButtonLoading = true;
    const validationCheck = this.validationCheck("all");
    if (!validationCheck) {
      await this.sleep(1000);
      this.model.saveButtonLoading = false;
      this.model.saveButtonActive = true;
      this.model.pageLoading = false;
      return;
    }
    let response: any;
    this.loadingOn();
    await axios
      .post(
        `https://${process.env.VUE_APP_API_URL}/PlanCreate/Save`,
        {
          name: this.model.name.value,
          explanation: this.model.explanation.value,
          price: Number(this.model.price.value),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + (await this.creatorUserManager.getJwtToken()),
          },
        }
      )
      .then((response) => {
        response = response.data;
      })
      .catch((ex) => {
        throw ex;
      });
    this.$router.push({
      name: "CreatorPlans",
      params: {},
    });
    this.loadingOff();
    this.model.saveButtonCompleted = true;
    await this.sleep(2000);
    this.model.saveButtonActive = true;
    this.model.saveButtonLoading = false;
    this.model.pageLoading = false;
  }
  public sleep(msec: number): Promise<void> {
    return new Promise(function(resolve) {
      setTimeout(function() {
        resolve();
      }, msec);
    });
  }
  public hankaku2Zenkaku(text: string): string {
    return text.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
  }
}
